import {PriceModel} from './Price.model';
import {PickupInfoModel} from './PickupInfo.model';
import {ScheduledDeliveryOptionModel} from './ScheduledDeliveryOption.model';
import {ShippingOptionFragment} from '../../gql/graphql';

export class ShippingOptionModel {
  public code: string;
  public title: string;
  public pickupInfo?: PickupInfoModel;
  public deliveryTime: string;
  public price: PriceModel;
  public hasTimeSlots?: boolean;
  public scheduledDeliveryOptions?: ScheduledDeliveryOptionModel[];
  public timeSlotDays?: {[date: string]: TimeSlotOption[]};

  constructor(
    shippingOption: ShippingOptionFragment,
    aggregatedDeliveryOptions?: ShippingOptionFragment[],
    shouldSupportTimeSlotsInCheckout?: boolean
  ) {
    this.code = shippingOption.code!;
    this.title = shippingOption.title!;
    this.pickupInfo = shippingOption?.logistics?.pickupDetails
      ? new PickupInfoModel(shippingOption?.logistics)
      : undefined;
    this.deliveryTime = shippingOption.logistics?.deliveryTime ?? '';
    this.price = new PriceModel(shippingOption.cost?.price);
    this.hasTimeSlots =
      shouldSupportTimeSlotsInCheckout &&
      Boolean(shippingOption?.logistics?.deliveryTimeSlot) &&
      Boolean(aggregatedDeliveryOptions);
    if (this.hasTimeSlots) {
      this.timeSlotDays = getTimeSlotDays(aggregatedDeliveryOptions!);
    } else {
      this.scheduledDeliveryOptions = aggregatedDeliveryOptions?.map(
        (option) => new ScheduledDeliveryOptionModel(option)
      );
    }
  }
}

function getTimeSlotDays(aggregatedDeliveryOptions: ShippingOptionFragment[]) {
  const timeSlotOptions = aggregatedDeliveryOptions
    .filter((option) => !!option.logistics?.deliveryTimeSlot?.from)
    .map((option) => new TimeSlotOption(option));

  return timeSlotOptions.reduce<{[dateString: string]: TimeSlotOption[]}>((acc, option) => {
    acc[option.date.toDateString()] = acc[option.date.toDateString()] || [];
    acc[option.date.toDateString()].push(option);
    return acc;
  }, {});
}

export class TimeSlotOption {
  public id: string;
  public date: Date;
  constructor(option: ShippingOptionFragment) {
    this.id = option.code!;
    this.date = new Date(+option.logistics!.deliveryTimeSlot!.from!);
  }
}
